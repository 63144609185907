<template>
	<div class="all_detail">
		<div class="detail">
			<div class="person_infor">
				<div class="login_top">
					<div class="img"> <img src="@/assets/login/flag.png"></div>
					<div class="login_title">{{$t('set.aboutUs')}}</div>
				</div>
			</div>
			<div class="content">
				<div v-html="content"></div>
			</div>
			<div class="red">
				<div class="red_left">
					<img src="@/assets/person/red_icon.png">
				</div>
				<div class="red_right">
					<div class="item">{{$t('officialPhone')}}：{{companyPhone}}</div>
					<div class="item">{{$t('officialEmail')}}：{{companyEmail}}</div>
					<div class="item">{{$t('officialWhatsAPP')}}：{{companyWhatsApp}}</div>
				</div>
			</div>
			<div class="blue">
				<div class="blue_left">
					<img src="@/assets/person/blue_icon.png">
				</div>
				<div class="blue_right">
					{{$t('officialAddress')}}：{{companyAddress}}
				</div>
			</div>
			<div class="map">
				<div id="mapCanvas" style="width: 862px;height: 400px;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				map: '',
				companyAddress: '',
				companyPhone: '', //
				companyEmail: '',
				companyWhatsApp: '',
				content: '', //公司简介
				lang: '',
			}
		},
		mounted() {
			this.lang = localStorage.getItem('lang');

			this.initMap();
			this.getAboutUs();
			this.getArticle();

			// 监听localstorage里面的数据
			window.addEventListener("setItemEvent", (e) => {
				if (e.key === "lang") {
					this.lang = e.newValue;
					this.getArticle();
				}
			})
		},
		methods: {
			getAboutUs() {
				this.$http.aboutUs().then(res => {
					if (res.code == 1) {
						this.companyPhone = res.data.kefu_phone;
						this.companyAddress = res.data.official_addr;
						this.companyEmail = res.data.kefu_email;
						this.companyWhatsApp = res.data.whatsApp;
					} else {
						this.$message.info(res.data.msg);
					}
				})
			},
			// 获取公司简介
			getArticle() {
				this.$http.getArticle({
					category_id: this.lang == 'zh' ? 28 : 26,
				}).then(res => {
					if (res.code == 1) {
						this.content = res.data.content;
					} else {
						this.$message.error(res.msg);
					}
				})
			},

			initMap() {
				let mapCenter = {
					lat: 9.028779900000002,
					lng: -79.4777155
				};
				// 创建地图实例
				this.map = ''
				this.map = new google.maps.Map(document.getElementById('mapCanvas'), { // 在map_canvas中生成一个实例地图
					center: mapCenter, // 中心点
					zoom: 12, // zoom是缩放比例，以中心点放大多少倍
					// mapTypeId: google.maps.MapTypeId.ROADMAP, //  地图展示的类型 
					language: "en",

				})

				const marker = new google.maps.Marker({
					position: mapCenter,
					map: this.map,
				});
			}
		}
	}
</script>

<style scoped="" lang="less">
	.all_detail {
		background: #F6F7F9;
		padding: 24px 0 64px;
	}

	.detail {
		box-sizing: border-box;
		width: 1200px;
		margin: 0 auto;
		background-color: #FFFFFF;
		padding-bottom: 40px;
	}

	.person_infor {
		width: 1200px;
	}

	.login_top {
		display: flex;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;

		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;
		}
	}

	.content {
		box-sizing: border-box;
		width: 1200px;
		padding: 24px 24px;
		text-align: left;
		line-height: 28px;
		color: #333333;
	}

	.red {
		margin-left: 32px;
		padding-left: 24px;
		text-align: left;
		display: flex;
		align-items: center;
		width: 600px;
		height: 115px;
		background-image: url(../../assets/person/red_bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.red_left {
			img {
				width: 48px;
				height: 48px;
			}
		}

		.red_right {
			padding-top: 10px;
			margin-left: 24px;
			font-size: 16px;

			.item {
				height: 34px;
			}
		}
	}

	.blue {
		margin-top: 24px;
		margin-left: 32px;
		padding-left: 24px;
		text-align: left;
		display: flex;
		align-items: center;
		width: 600px;
		height: 98px;
		background-image: url(../../assets/person/blue_bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.blue_left {
			img {
				width: 48px;
				height: 48px;
			}
		}

		.blue_right {
			margin-left: 24px;
			font-size: 16px;
		}
	}

	.map {
		margin: 32px;
	}
</style>
